import React, { useState } from "react"
import Navbar from "./Navbar/Navbar"
import Sidebar from "./Sidebar/Sidebar"

function Navigation({ dark = false, absolute = false, fixed = false, scrollWatch = false }) {
    const [active, showNav] = useState(false)
    return (
        <React.Fragment>
            <Navbar showNav={() => showNav(true)} dark={dark} absolute={absolute} fixed={fixed} scrollWatch={scrollWatch}/>
            <Sidebar active={active} hideNav={() => showNav(false)}/>
        </React.Fragment>
    )
}

export default Navigation