import React, { useState, useEffect } from "react"
import Logo from '../../../images/logo.svg'
import './Navbar.css'
import { Link } from 'gatsby'


function Navbar({ dark,showNav,absolute, fixed, scrollWatch }) {
    let scrollDark,toggleScrollDark;
    [scrollDark, toggleScrollDark ] = useState(false);
    useEffect(() => {
        if(scrollWatch){
            if(typeof window !== 'undefined'){
                window.addEventListener('scroll', () => {
                    if(window.scrollY > window.innerHeight){
                        toggleScrollDark(true)
                    }else{
                        toggleScrollDark(false)
                    }
                })
            }
        }
    })
    return (
        <nav className={`nav ${absolute && 'nav--absolute'} ${fixed && 'nav--fixed'}`}>
            <Link to="/">
                <img src={Logo} className="nav__logo" alt="Logo"/>
            </Link>
            <button className="nav__button" onClick={showNav}>
                <span className={`bar ${dark && 'bar--dark'} ${scrollDark && 'bar--scroll-dark'}`}/>
                <span className={`bar bar--small ${dark && 'bar--dark'} ${scrollDark && 'bar--scroll-dark'}`}/>
            </button>
        </nav>
)
}

export default Navbar