import React from "react"
import IGLogoDark from "../../images/ig-dark.svg"
import IGLogo from "../../images/ig.svg"
import FBLogoDark from "../../images/fb-dark.svg"
import FBLogo from "../../images/fb.svg"
import TWLogoDark from "../../images/tw-dark.svg"
import TWLogo from "../../images/tw.svg"
import './SocialMediaIcons.css'

function SocialMediaIcons({ dark, right, className = '' }) {
    return (
        <div className={`social-media-icons ${right && 'social-media-icons--right'} ${className}`}>
            <a href="https://www.instagram.com/tech4dev/" className="social-media-icon" target="_blank" rel="noopener noreferrer">
                <img src={dark ? IGLogoDark : IGLogo} alt="Instagram Logo"/>
            </a>
            <a href="https://web.facebook.com/Tech4DevHQ/" className="social-media-icon" target="_blank" rel="noopener noreferrer">
                <img src={dark ? FBLogoDark : FBLogo} alt="Facebook Logo"/>
            </a>
            <a href="https://twitter.com/Tech4DevHQ" className="social-media-icon" target="_blank" rel="noopener noreferrer">
                <img src={dark ? TWLogoDark : TWLogo} alt="Twitter Logo"/>
            </a>
        </div>
    )
}

export default SocialMediaIcons