import React, { useState } from "react"
import './Sidebar.css'
import IGLogo from '../../../images/ig-dark.svg'
import TWLogo from '../../../images/tw-dark.svg'
import FBLogo from '../../../images/fb-dark.svg'
import { Link } from 'gatsby'
import CancelIcon from '../../../images/close-button.svg'

function Sidebar({ active = false, hideNav }) {
    const [ hide, toggleHide ] = useState(false)

    return (
        <div className={`sidenav-container ${active && 'sidenav-container--active'}`}>
            <aside className={`sidebar ${active && 'sidebar--active'} ${hide ? 'sidebar--hide' : 'sidebar--show'}`}>
                <button className="sidebar__button" onClick={() => {
                    toggleHide(true)
                    setTimeout(() => {
                        hideNav(false)
                        toggleHide(false)
                    }, 410)
                }}>
                    <img src={CancelIcon} alt="" className="sidebar__button-img"/>
                </button>
                <ul className="sidebar__links">
                    <Link to='/about-us' className="sidebar__link" activeClassName="sidebar__link--active" partiallyActive>
                        About Us
                    </Link>
                    <Link to='/become-a-champion' className="sidebar__link" activeClassName="sidebar__link--active" partiallyActive>
                        Become a BDEI Champion
                    </Link>
                    <Link to='/volunteer' className="sidebar__link" activeClassName="sidebar__link--active" partiallyActive>
                        Volunteer
                    </Link>
                    <Link to='/contact-us' className="sidebar__link" activeClassName="sidebar__link--active" partiallyActive>
                        Contact Us
                    </Link>
                </ul>
                <div className="sidebar__social-media-icons">
                    <a href="https://www.instagram.com/tech4dev/" className="sidebar__social-media-icon" target="_blank" rel="noopener noreferrer">
                        <img src={IGLogo} alt="Instagram Logo"/>
                    </a>
                    <a href="https://web.facebook.com/Tech4DevHQ/" className="sidebar__social-media-icon" target="_blank" rel="noopener noreferrer">
                        <img src={FBLogo} alt="Facebook Logo"/>
                    </a>
                    <a href="https://twitter.com/Tech4DevHQ" className="sidebar__social-media-icon" target="_blank" rel="noopener noreferrer">
                        <img src={TWLogo} alt="Twitter Logo"/>
                    </a>
                </div>
            </aside>
        </div>
    )
}

export default Sidebar